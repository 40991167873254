import {defineComponent} from 'vue';
import SearchComponent from '@/components/FiltersComponents/Search/SearchComponent.vue';
import ByTypes from '@/components/FiltersComponents/ByTypes/ByTypes.vue';
import {useStore} from 'vuex';
import {featured} from '@/helpers/featured';
import router from '@/router';

export default defineComponent({
  name: 'HeaderComponent',
  
  components: {
    SearchComponent,
    ByTypes
  },
  
  computed:{
    currentCatalogName: function()
    {
      const store = useStore();
      const { TYPES, BRANDS } = featured();
      return TYPES.find( t => t.id === store.state.filters['category']).text;
    },
    
    isIndex: function()
    {
      return router.currentRoute.value.fullPath === '/';
    }
  },

  setup()
  {
    const store = useStore();
    const toggleCatalog = () =>
    {
      store.state.showCatalog = !store.state.showCatalog;
    };
    
    return { toggleCatalog, store };
  }
});