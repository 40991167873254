import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// function generateMeta(title: string) {
//   const prefix = /dev|localhost/.test(location.host)  ? 'DEV ' : '';

//   return {
//     title: `${prefix}${title}`,
//   };
// }

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Collections',
    component: () => import(/* webpackChunkName: "collections" */ '@/views/Collections/CollectionsView.vue'),
    meta: { title: '3D каталог'},
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import(/* webpackChunkName: "details" */ '@/views/Product/ProductView.vue'),
    meta: { title: 'Product'},
    props: true,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin/AdminView.vue'),
    meta: { title: 'Admin'},
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error/ErrorView.vue'),
    meta: { title: 'Ошибка 404'},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;

  // const toReload = localStorage.getItem('toReload');
  // if (toReload !== 'reloaded') {
  //   localStorage.setItem('toReload', to.path);
  // }

  next();
});

// router.afterEach((to) => {
//   const toReload = localStorage.getItem('toReload');
//   // If another page is opened reload it
//   if (to.path !== toReload) {
//     localStorage.setItem('toReload', to.path);
//     router.go(0);
//   } else {
//     localStorage.setItem('toReload', 'reloaded');
//   }
// });

export default router;
