<template>
  <header :class="'nav nav--mobile'">
    <!--start nav-->

    <div
      :class="[isIndex ? 'nav__desktop nav__desktop--active' : 'nav__desktop nav__desktop--active ignore_media']"
      ref="navBar"
    >
      <!--nav__desktop-->
      <div class="nav__logo nav__logo-desktop">
        <a class="brand_text" href="/">
          <!--<img src="@/assets/images/logo.svg" alt="logo-svg" />-->
          Demo-Store
        </a>
      </div>
      <div class="filters">
        <div>
          <input class="catalog_button" type="button" value="Каталог" @click="toggleCatalog" />
          <ByTypes byCategory v-if="store.state.showCatalog"></ByTypes>
        </div>
        <SearchComponent @click="store.state.showCatalog = false"></SearchComponent>
      </div>
    </div>
    <!--end nav__desktop-->
  </header>
  <!--end nav-->
</template>

<style lang="scss">
@import './scss/HeaderComponent.scss';
</style>
<script>
import HeaderComponent from './logic/HeaderComponent';
export default HeaderComponent;
</script>
