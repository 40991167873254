
import { defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import FooterComponent from './components/Footer/FooterComponent.vue';
import Header from './components/Header/HeaderComponent.vue';

export default defineComponent({
  name: 'App',
  props: {
    msg: String,
  },

  setup() {
    const store = useStore();

    onBeforeMount(() => {
      store.dispatch('userModule/checkAuth');
      store.dispatch('cartModule/getCart');
    });
  },
  components: {
    Header,
    FooterComponent,
  },
});
