const products = {
  data: [
    {
      id: 1,
      token: 'a9c8ef06-895c-4642-a13d-4523e9726b03',
      name: 'Комод 1500 Сканди',
      price: '11090',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/dcff5e63-8620-4ba0-b3c3-a83239b27448/main_icon.jpg',
      description:
        'Комод 1500 Сканди - это настоящая находка для любителей стиля. Его изящество и надежность не оставят равнодушными даже самых привередливых покупателей, к тому же по такой справедливой цене.',
      discount: 20,
      endprice: '8872',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 5,
    },
    {
      id: 2,
      token: '26c31384-01d4-4d15-a94e-115e6c4f44e6',
      name: 'Кожаный диван Rock Star',
      price: '250800',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/fc894fed-8099-4f10-962f-c97b9e98df55/main_icon.jpg',
      description:
        'Диван Rock Star со встроенным в подлокотник ящиком для хранения впишется в любое помещение и привнесет особую эстетику в дизайн благодаря современным штрихам.',
      discount: 0,
      endprice: '250800',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      betterInApp: true,
      order: 1,
    },
    {
      id: 3,
      token: '85871965-2285-4ca3-b0b1-8e861f06c14c',
      name: 'МФУ лазерный Canon',
      price: '69010',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/a7bc246b-0bcc-446f-a13b-684521ff8831/main_icon.jpg',
      description:
        'Технология: лазерный, черно-белый, двусторонняя печать, A4,\nРазрешение: ч/б 1200 x 1200 dpi,\nСкорость печати: ч/б (A4) до 38 стр/мин;\nЛотки: подача 250 листов, выход 150 листов;\nДополнительные функции: сканирование, копирование',
      discount: 0,
      endprice: '69010',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      order: 1,
    },
    {
      id: 6,
      token: '5d2a142d-e559-46a8-b750-dc4635f18b52',
      name: 'Кресло для гостиной',
      price: '8990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/e20d6eb1-552f-454c-b30d-7230535fe95e/main_icon.jpg',
      description:
        'Каркас из натурального дерева и широко расставленные ножки гарантируют надежность и устойчивость конструкции. Удобная мягкая спинка и широкое сиденье обеспечат комфорт и уют.',
      discount: 0,
      endprice: '8990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 3,
    },
    {
      id: 7,
      token: 'c5fb1650-6aa1-426d-b607-13fcc0e54607',
      name: 'Кресло Сенатор',
      price: '7590',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/1495cf96-72b1-4e7d-a9fb-13d887e4306e/main_icon.jpg',
      description:
        'Офисное кресло, подходит для оборудования рабочих мест в офисе, а также для работы за компьютером дома.',
      discount: 0,
      endprice: '7590',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 3,
    },
    {
      id: 8,
      token: 'b89d7f45-baa5-4435-983e-366b381c8794',
      name: 'Кресло Ermes',
      price: '35585',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/47c6e4d0-8606-4d0b-b2d0-830738b374ef/main_icon.jpg',
      description:
        'Дизайнерское кресло ERMES - настоящее украшение гостиной. Стильная модель отлично подойдет для размещения в домашней обстановке.\nБлагодаря небольшому весу оно станет достаточно мобильным элементом гостиной зоны. Высокая спинка обеспечивает хорошую поддержку.\nКресло создано из материалов премиального качества, что обеспечивает его надежность и долгий срок службы. Каркас кресла выполнен из березовой фанеры и массива, ножки из массива бука. В качестве наполнителя выступает эластичный пенополиуретан разной плотности, лебяжий пух.',
      discount: 0,
      endprice: '35585',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 3,
    },
    {
      id: 9,
      token: '28bdc97b-236c-4edb-a2ff-b43f0fe69497',
      name: 'Кожаный диван "Техас" 2-местный',
      price: '260000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/f27d0b35-1440-4f73-9d0a-803626696b47/main_icon.jpg',
      description:
        'Комфортная и воздушная посадка: наполнитель спинки сидений - синтетический пух. Наполнитель сидений - высокоэластичный пенополиуретан на пружине "зиг-заг".\nРегулируемые подголовники: можно легко менять угол наклона подголовника в диапазоне 120 градусов (механизм японского производства). Таким образом меняется высота спинки сиденья.',
      discount: 0,
      endprice: '260000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 1,
    },
    {
      id: 10,
      token: '11535702-ea35-4b02-b10b-d3c8023963d4',
      name: 'Шкаф-купе Stanley',
      price: '74000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/1b476aea-c99e-4e5a-b2e0-c9233fea2f8a/main_icon.jpg',
      description:
        'Шкаф-купе Stanley для спальни эффектно смотрится, больше напоминает абстракцию, мозаику, настенный декор. Все благодаря оригинальному оформлению фасадов. Цвета и оттенки створок можно корректировать. Их изящно дополняет раскладка.',
      discount: 0,
      endprice: '74000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      betterInApp: true,
      order: 6,
    },
    {
      id: 11,
      token: '0b8ee0bf-182f-4a14-bc32-ed8e89da1684',
      name: 'Комод Арт Сканди Айс Амбер',
      price: '69990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/83cd4691-c650-4e8f-874e-18ead13a8f92/main_icon.jpg',
      description:
        'Комод с ящиками или экспонат музея современного искусства? В комоде Арт Сканди настолько тесно переплетены эстетика и функциональность, что едва ли захочется отнести к нему слово мебель . Это роскошный элемент декора в современном интерьере, предназначенный для хранения вещей. Особое украшение комода - фасад с тропическими листьями. Изюминка изделия шикарная кованая ручка округлой формы ручная работа кузнеца.',
      discount: 0,
      endprice: '69990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      betterInApp: false,
      order: 5,
    },
    {
      id: 12,
      token: '884574ed-3bcf-414a-aca5-ec4a451da4f1',
      name: 'Композиция для спальни YM06',
      price: '299760',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/005f0345-1aae-49a5-a2a5-4a3e7c42f0cd/main_icon.jpg',
      description:
        'Гарнитур выполнен в минималистическом стиле и прекрасно подойдет для просторного спального помещения. Он добавит интерьеру ощущение света и легкости. Обрамление вокруг кровати, а также низ встроенной подвесной тумбочки обработаны натуральным шпоном цвета высокогорный дуб. Такая поверхность создает впечатление натурального древесного массива, к которому так и тянет прикоснуться и ощутить тепло настоящего дерева.',
      discount: 0,
      endprice: '299760',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 7,
    },
    {
      id: 13,
      token: '58b382f7-93a6-4cff-9d4f-cfa349c2bac8',
      name: 'Компьютерный стол Сокол',
      price: '13190',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/eb1ad82b-aa0f-48ef-a77f-47d0358f9631/main_icon.jpg',
      description:
        'Стол Сокол - это отличное решение для оформления полноценной рабочей зоны. Классический дизайн стола прекрасно подойдет к Вашему интерьеру и позволит расположить необходимую Вам компьютерную технику. Специальные полки под системный блок и клавиатуру позволяют Вам не занимать основное рабочее пространство. На просторную столешницу поместится монитор с большой диагональю. Выдвижные ящики в боковой части стола позволяют хранить все необходимые документы.',
      discount: 0,
      endprice: '13190',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 4,
    },
    {
      id: 14,
      token: '3430a950-1fe7-4d0a-b33e-8a342c5e5e24',
      name: 'Стол ЭОП-6 Раздвижной',
      price: '45870',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/412b0ca9-8d44-4cc6-976b-18aa0fc6a15e/main_icon.jpg',
      description:
        'Ножки подстолья выполняются из массива натурального бука, столешница из МДФ, облицованного шпоном натурального бука или дуба. Конфигурация стола - раздвижной.',
      discount: 0,
      endprice: '45870',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 4,
    },
    {
      id: 15,
      token: '71c5f2c4-52d5-4b2b-8e61-dc54447bac8e',
      name: 'Вешалка настенная Терра',
      price: '43919',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/92ec9402-a5c8-451c-8545-ca2c94a35da2/main_icon.jpg',
      description:
        'Настенная вешалка - незаменимый предмет аксессуар для дома, квартиры, дачи. Помимо элемента мебели, вешалка на стену является одним из основных составляющих декора прихожей, коридора, ванной, гардеробной комнаты. Основное назначение крючков - хранение вещей: вешалка предназначена для одежды, для шапок и других головных уборов, шарфов, сумок, зонтов, а также подойдет для полотенец и халатов в ванную.',
      discount: 0,
      endprice: '43919',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      betterInApp: false,
      order: 8,
    },
    {
      id: 16,
      token: 'ceabbdba-5393-4a3d-9364-5c20f80778ae',
      name: 'Телевизор Xiaomi',
      price: '24990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/9d7cbd0c-6ebc-4069-9107-31bafd8675ed/main_icon.jpg',
      description:
        'Телевизор Xiaomi на базе Android TV 11 обеспечивает мгновенный доступ к фильмам и шоу на любой вкус. Встроенный Google Ассистент полностью меняет концепцию управления контентом. Благодаря Dolby Audio и DTS Virtual:X Sound вам гарантированы впечатления, как в кинотеатре, а безрамочный дизайн и высокое разрешение HD позволяют полностью погрузиться в просмотр.',
      discount: 0,
      endprice: '24990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      betterInApp: true,
      order: 1,
    },
    {
      id: 18,
      token: 'ec37db10-99c5-4eb0-a31a-fc111e775a17',
      name: 'Робот-пылесос iRobot Roomba',
      price: '23900',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/ac59391b-5934-4a19-8ddb-f4aa9ab591d7/main_icon.jpg',
      description:
        'В iRobot Roomba использованы валики-скребки инновационного дизайна. Это позволяет обеспечить очень тщательную уборку. Конструкция вакуумной системы позволила 5-кратно увеличить силу всасывания по сравнению с базовой серией без увеличения потребляемой мощности. В iRobot Roomba мотор вынесен за пределы мусоросборника. Теперь вы можете не только вытряхивать мусор из контейнера, его можно мыть водой. ',
      discount: 0,
      endprice: '23900',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      order: 1,
    },
    {
      id: 19,
      token: '2b7cb364-cd03-4644-a542-bb5790a18127',
      name: 'Тостер BORK T701',
      price: '14000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/7e313984-699a-4cc5-8bc1-e37318de0921/main_icon.jpg',
      description:
        '6 степеней поджаривания. Тостер позволяет регулировать степень поджаривания хлеба, а с помощью функции A BIT MORE легко дополнительно подогреть тосты. Тостер T701 позволяет разморозить хлеб, а после автоматически поджарить ломтики. Благодаря функции LIFT&LOOK легко проконтролировать процесс поджаривания без прерывания работы.',
      discount: 0,
      endprice: '14000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      order: 1,
    },
    {
      id: 20,
      token: '61c529a4-8b34-47dc-b3be-3c493d8673a2',
      name: 'Садовое кашпо-бочонок для цветов',
      price: '4950',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/9ba14565-a020-48da-be78-7e368e7d7469/main_icon.jpg',
      description:
        'Независимо от того, какой стиль дизайна вы выберете, использование горшков и кашпо под дерево будет смотреться актуально. Цветы создают атмосферу уюта и улучшают настроение, а сочетание с красивым кашпо, имитирующим деревянное изделие, добавляет эффектности.',
      discount: 0,
      endprice: '4950',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      betterInApp: true,
      order: 1,
    },
    {
      id: 21,
      token: '968e4953-20d8-4ec6-9ea7-fafa010ae50a',
      name: 'Аккумуляторная ударная дрель DEWALT DCD716D2',
      price: '14750',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/45dcddbb-23b5-41dc-9088-6ac9b9d84c2a/main_icon.jpg',
      description:
        'Ударная дрель-шуруповерт Dewalt предназначена для закручивания шурупов и сверления отверстий. Возможность регулировки числа оборотов позволяет подобрать оптимальный рабочий режим. Для эффективного освещения рабочей зоны в затемненных помещениях предусмотрена светодиодная подсветка. В качестве источника питания используется литий-ионный аккумулятор без саморазряда и эффекта памяти.',
      discount: 0,
      endprice: '14750',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'instruments',
      order: 1,
    },
    {
      id: 22,
      token: '536faa2f-ed42-42ca-bd69-bba31a69d6dd',
      name: 'Log Splitter TOOL POWER 60-Ton',
      price: '180000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/cad6a141-2559-4319-9932-4caf39137881/main_icon.jpg',
      description:
        'Tool Power Genuine Force 60 Ton Log Splitter is a high quality branded item for long run and comes with a 15-hp electric start LIFAN high performance long lasting racing engine.',
      discount: 0,
      endprice: '180000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'instruments',
      order: 1,
    },
    {
      id: 23,
      token: 'b3a3117c-514c-48c5-9eb4-1cf2ac8c390f',
      name: 'Мотошлем Off-Road Helmet',
      price: '3470',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/67685ed6-0f2c-4ced-af48-d9f636f8f675/main_icon.jpg',
      description:
        'Подкладка шлемов изготовлена из экологически чистого материала, с тонкой обработкой, мягкая и удобная, она съемная для удобной очистки, прочная и изысканная.',
      discount: 0,
      endprice: '3470',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'helmets',
      betterInApp: true,
      order: 1,
    },
    {
      id: 24,
      token: 'f14f35a2-64a0-4234-96f4-82693e346328',
      name: 'ARAI RX-7V MAZE RED ',
      price: '91190',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/a2d7bcaa-91d3-4e1d-b77f-438d382f99af/main_icon.jpg',
      description:
        'Прежде всего, спортивный шлем Arai отличается особой конструкцией оболочки, PB-SNC2. Помимо специальных материалов, увеличивающих прочность и снижающих массу шлема, RX-7V имеет распределенную по зонам, варьирующуюся толщину скорлупы и разное направление волокон композитного материала, способствующее наибольшей прочности.',
      discount: 0,
      endprice: '91190',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'helmets',
      betterInApp: true,
      order: 1,
    },
    {
      id: 25,
      token: '1a92f3dc-9e72-4a96-b8df-6ce5923da094',
      name: 'Кожаный мотошлем',
      price: '8473',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/2b0a84bc-f054-4167-a33b-94a883837c5a/main_icon.jpg',
      description: 'Винтажный мотоциклетный шлем из искусственной кожи с открытым лицом.',
      discount: 0,
      endprice: '8473',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'helmets',
      betterInApp: true,
      order: 1,
    },
    {
      id: 27,
      token: 'ba6bdad0-363b-456a-a170-3ee658e0a42b',
      name: 'Диван Вирджиния',
      price: '50000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/db6956ef-d44f-4a20-b326-c41d4cbd6651/main_icon.jpg',
      description:
        'Модульный диван Вирджиния изготавливается на заказ по размерам клиента. Ширина: от 1080 до 4000 мм, глубина: от 950 до 1200 мм. Высота подлокотников также регулируется до комфортного уровня. В зависимости от шиирины дивана, он может включать в себя от 1 до 4 модулей. Указана минимальная цена за одномодульный диван.',
      discount: 0,
      endprice: '50000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      betterInApp: true,
      order: 1,
    },
    {
      id: 28,
      token: '2765be22-cb50-410f-9522-bfc80caf78cb',
      name: 'Шкаф ЛДСП (на заказ)',
      price: '7900',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/484a3f43-153b-43da-bff0-537002299017/main_icon.jpg',
      description:
        'Шкаф из ЛДСП изготавливается по размерам заказчика. Ширина от 500 до 1500 мм, высота от 1000 до 3000 мм, глубина от 200 до 1000 мм. Указана минимальная цена',
      discount: 0,
      endprice: '7900',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 6,
    },
    {
      id: 29,
      token: 'c252e075-ca9b-47b6-9384-d21c99a871dd',
      name: 'Табурет Лофт',
      price: '2900',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/1f93078d-308b-4260-9182-67cbeb58808a/main_icon.jpg',
      description:
        'Табурет Лофт. Материал сидения - ЛДСП, каркас - металл. Изготавливается по размерам заказчика: ширина, высота и глубина в диапазоне от 150 до1500 мм. Указана минимальная цена.',
      discount: 0,
      endprice: '2900',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 2,
    },
    {
      id: 30,
      token: '3176cce5-ea36-4a39-89c4-73a1e39287fd',
      name: 'Угловой диван-кровать Лакки',
      price: '53811',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/b692670d-c2e0-4e79-9fff-5d3d6eb0b79f/main_icon.jpg',
      description:
        'Угловой диван-кровать «Лакки» имеет классический внешний вид: три большие прямоугольные подушки спинки, просторное сиденье с точечными утяжками на ткани, подлокотники, расширяющиеся в верхней части. Если вы не слишком жалуете дизайнерские эксперименты с формой, предпочитаете сдержанные, традиционные интерьеры и ищите аналогичную мебель, «Лакки» станет подходящим выбором для вас. При этом функциональность модели соответствует всем современным стандартам: секцию канапе можно переставить слева направо или наоборот самостоятельно, без помощи сборщиков; наполнитель рассчитан на ежедневные длительные нагрузки и обладает анатомическими свойствами; нагрузка распределяется равномерно, поясничный и плечевые пояса получают необходимую поддержку.',
      discount: 0,
      endprice: '53811',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 1,
    },
    {
      id: 31,
      token: 'a5600510-5c18-4791-8895-dcd5ff236735',
      name: 'Диван-кровать Фьорд',
      price: '61677',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/608b6d03-31c4-4d2b-8989-dbdda30b07f7/main_icon.jpg',
      description:
        'Диван-кровать «Фьорд» сочетает в себе функциональность и лаконичный внешний вид. В его дизайне присутствуют все основные элементы, свойственные скандинавскому стилю: тонкие подлокотники и мягкие подушки с отделкой модным «французским швом»; пара дополнительных миниатюрных подушек для украшения интерьера; декоративные складки в местах скругления подлокотника и сидения.',
      discount: 0,
      endprice: '61677',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 1,
    },
    {
      id: 32,
      token: 'c8941b13-c52f-4289-8708-8488ada3e89a',
      name: 'Стиральная машина Samsung WF602F8C',
      price: '57200',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/c4ec2f56-19cb-4d0b-b52f-f5e3a24a95f3/main_icon.jpg',
      description:
        'Работает тихо. При отжиме: не прыгает, нет сильной вибрации. Установлен бесколлекторный двигатель. Понятная символика при выборе режимов стирки. Отлично стирает крупногабаритные вещи. В этой машинке все продумано.',
      discount: 0,
      endprice: '57200',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      betterInApp: true,
      order: 1,
    },
    {
      id: 33,
      token: '95540e02-b70f-4205-8606-f1aea0af03dc',
      name: 'Микроволновая печь Sharp Carousel',
      price: '8990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/fcf2d33e-0374-48ec-8a9d-6c06dc766316/main_icon.jpg',
      description: 'Объем 24 л. Сенсорное управление. Гриль. Конвекция. Мощность СВЧ - 850 Вт.',
      discount: 0,
      endprice: '8990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      betterInApp: true,
      order: 1,
    },
    {
      id: 34,
      token: '08fca199-1101-4aed-8e79-25bf2e1fd15d',
      name: 'Костровая зона',
      price: '151600',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/f6eb54fb-316e-440a-a5e4-b43052a9019b/main_icon.jpg',
      description:
        'Специально для оформления костровых зон, мы разработали в единой стилистике уличную садовую мебель, которая не боится дождя, снега и перемены погодных условий.',
      discount: 0,
      endprice: '151600',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      order: 1,
    },
    {
      id: 35,
      token: 'd3941a87-cc3b-4727-a485-f7cc7fe7064c',
      name: 'Настенные часы "Механизм"',
      price: '1790',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/14cd6e1e-62f1-40cf-9122-5c65977aafdb/main_icon.jpg',
      description:
        'Такой стильный декор станет изюминкой любого интерьера, будь то спальня, каминный зал, кухня, прихожая, гостиная, ванная комната, и интересным интерьерным решением не только для дома и дачи (как комнатные или кухонные), но и для офисных помещений (офиса), детских центров, поликлиник.',
      discount: 0,
      endprice: '1790',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'watches',
      betterInApp: false,
      order: 1,
    },
    {
      id: 36,
      token: '74bb15f3-91b5-44f3-9d23-cf4735884945',
      name: 'Бензиновая пила STIHL',
      price: '105000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/8b08acd5-af51-4173-98da-74d65fe342a2/main_icon.jpg',
      description:
        'Тип двигателя: бензиновый, мощность (л.с.): 2.40 л.с., длина шины: 35 см, шаг цепи: 3/8 дюйма, объем двигателя: 35.20 см³, антивибрация, вес: 3.90 кг',
      discount: 0,
      endprice: '105000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'instruments',
      order: 1,
    },
    {
      id: 37,
      token: '937596cb-e6cd-4997-93b0-a143d5ac6d04',
      name: 'Комод Modis Interiors',
      price: '25000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/900a6eb0-db68-4157-9956-c093e512831c/main_icon.jpg',
      description:
        'Комод изготавливается по размерам заказчика. Ширина от 500 до 2000 мм, высота от 700 до 1200 мм, глубина от 300 до 1000 мм. В зависимости от ширины у комода может быть 1 или 2 секции ящиков. Указана минимальная цена',
      discount: 0,
      endprice: '25000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      betterInApp: true,
      order: 5,
    },
    {
      id: 38,
      token: '6cc325f8-37be-41a8-b26a-d12d076838c6',
      name: 'Утюг Philips Azur Ionic',
      price: '4000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/ade3adc4-6172-430d-8151-2198b1795986/main_icon.jpg',
      description:
        'Этот паровой утюг позволяет легко разглаживать даже жесткие складки благодаря мощной подаче пара. Керамическая подошва обеспечивает гладкое скольжение, а резервуара для воды 300 мл хватит для глажения небольшой стопки вещей даже без пополнения.',
      discount: 0,
      endprice: '4000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      order: 1,
    },
    {
      id: 39,
      token: '52a714fc-0954-40dc-9f9e-9bca599967b6',
      name: 'Беседка для дачи',
      price: '350000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/298ca3c9-c88d-4f2e-9c12-778e0fac29fc/main_icon.jpg',
      description:
        'Беседка для дачи сделана из высококачественных материалов, пропитанных антисептиком "Палисандр". Неповторимый вид беседки придаст уют Вашему участку.',
      discount: 0,
      endprice: '350000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      betterInApp: false,
      order: 1,
    },
    {
      id: 40,
      token: '00481c66-2fe9-4923-8beb-7224a83b6e5c',
      name: 'Садовые качели',
      price: '15000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/05fc9014-29f0-45a9-99d7-1b0f93960128/main_icon.jpg',
      description:
        'Все дети любят качаться на качелях! А родители любят своих детей. поэтому выбирают для них всё только безопасное и надёжное. Изделие изготовлено из дерева — это экологически чистый и безопасный материал. Даже если качели находятся на улице под прямыми солнечными лучами, ваш ребёнок не обожжётся.',
      discount: 0,
      endprice: '15000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      order: 1,
    },
    {
      id: 41,
      token: '32dcde55-b9fd-4dac-8ec1-f5a60077fb68',
      name: 'Кованая садовая скамейка',
      price: '85000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/ba760db8-378b-4f12-b10b-eb30724f5f3b/main_icon.jpg',
      description:
        'Садовые скамейки должны служить не один год. Их изготавливают из различных материалов. Однако большинство из них уступают кованому металлу. Металл — это материал, из которого можно сделать практически все. Причем любое изделие обрабатывается антикоррозийной краской. ',
      discount: 0,
      endprice: '85000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      betterInApp: true,
      order: 1,
    },
    {
      id: 42,
      token: 'caa65289-ac3f-4cc2-a3bd-fb48088098dd',
      name: 'Maurice Lacroix',
      price: '96100',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/1e4c2ec9-36d9-4cad-a40a-237f6f59724b/main_icon.jpg',
      description:
        'Швейцарские наручные часы Maurice Lacroix EL1098-PVP01-113-1 с хронографом. Этот стильный швейцарский хронограф покорит вас своим сбалансированным дизайном в сочетании с безупречным качеством.',
      discount: 0,
      endprice: '96100',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'watches',
      betterInApp: true,
      order: 1,
    },
    {
      id: 43,
      token: '0cc1b93b-0b04-4edc-9591-0da663778e67',
      name: 'Кровать Джорджио Косса',
      price: '55676',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/be053099-c673-4b36-85b8-f7d0b26e3270/main_icon.jpg',
      description:
        'Кровать с ортопедическим основанием и подъемным механизмом. Кровать достаточно широкая даже для двух человек, поэтому вы сможете принять удобную позу во время сна. Не переживайте за то, что спите на краю или мешаете своей второй половинке.',
      discount: 0,
      endprice: '55676',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 7,
    },
    {
      id: 44,
      token: '36a02bdf-00b0-41af-adcd-7dda36e7ba99',
      name: 'Гриль-мангал со складными полками и крышкой барбекю',
      price: '19990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/c9041dc3-d376-416d-becd-7e1dccf94955/main_icon.jpg',
      description:
        'Гриль-мангал предназначен для приготовления различных блюд на открытом воздухе. В чаше мангала можно готовить шашлыки, а также гриль/барбекю на решетке. На боковых стенках мангала имеются пазы под вертел.',
      discount: 0,
      endprice: '19990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      order: 0,
    },
    {
      id: 45,
      token: '66e91b03-1e1a-441c-aaf2-05396baf5390',
      name: 'Холодильник двухкамерный',
      price: '82530',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/ef3e959d-ef58-4f3e-95ac-768619491444/main_icon.jpg',
      description:
        'Этот двухкамерный холодильник оснащен энергоэффективным инверторным компрессором, благодаря чему уровень шума в процессе работы не превышает 42 дБ. В ночное время он совершенно не будет мешать спать. Полезный объем основной камеры составляет 335 л. Для разделения внутреннего пространства предусмотрено четыре стеклянных полки. Дополнительно присутствуют два выдвижных ящика из пластика, в которых удобно хранить фрукты и овощи.',
      discount: 0,
      endprice: '82530',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      betterInApp: false,
      order: 0,
    },
    {
      id: 46,
      token: '0271b25e-0f83-4612-bb52-45e17176d00f',
      name: 'Diesel Mr. Daddy DZ7315',
      price: '10190',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/0dcebd18-79f0-4d75-8992-8246deb5132d/main_icon.jpg',
      description:
        'Наручные часы Дизель – практичные и надежные, стильные и модные, созданные специально для тех, кто хочет выделиться, быть заметным и неповторимым.',
      discount: 0,
      endprice: '10190',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'watches',
      betterInApp: true,
      order: 0,
    },
    {
      id: 47,
      token: '3258ffd9-38df-4bf0-9ee7-a13158577f58',
      name: 'Rolex Oyster Perpetual Datejust',
      price: '1304000',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/0bc974a2-027c-4326-be1a-31fa2fca5c35/main_icon.jpg',
      description:
        'Модель Oyster Perpetual Datejust — настоящий эталон классических наручных часов, как в смысле дизайна, над которым время не властно, так и в смысле технических качеств.',
      discount: 0,
      endprice: '1304000',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'watches',
      betterInApp: true,
      order: 0,
    },
    {
      id: 48,
      token: '276cf62a-63e0-4e75-a76c-2d053f46450d',
      name: 'Кофемашина BOSCH, benvenuto B30',
      price: '14990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/4930f4fd-c28e-4be1-a045-db4224b62f29/main_icon.jpg',
      description:
        'Серия кофемашин Bosch Benvenuto выделяется доступной ценой. Нагревательный элемент – термоблок – отличается высочайшим качеством и стопроцентно делает возложенные на него функции. Кофемашина Bosch Benvenuto B30 имеет функцию автоматической декальцинации и таймер изготовления напитка.',
      discount: 0,
      endprice: '14990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      order: 0,
    },
    {
      id: 49,
      token: '92571c70-c848-427b-b521-fee0db79313e',
      name: 'Электрическая плита GE',
      price: '59990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/496e3c48-d4db-482a-b3d8-90a306aa2658/main_icon.jpg',
      description:
        'Эта линейка плит GE представлена в 2 цветах: нержавеющая сталь и черная нержавеющая сталь, которые гарантированно впишутся в ваш интерьер. Этот материал обеспечит элегантный внешний вид вашей кухни.',
      discount: 0,
      endprice: '59990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'tech',
      order: 0,
    },
    {
      id: 50,
      token: '8e523bc9-c4b3-41ee-88bd-b77dbbff4fb0',
      name: 'Пила торцовочная Metabo',
      price: '19990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/01dd6778-282d-4da8-8130-59c46747d987/main_icon.jpg',
      description:
        'Пила торцовочная предназначена для многократной угловой, наклонной и комбинированной распиловки древесины, хотя при смене диска вполне справляется с пластиками и алюминием.',
      discount: 0,
      endprice: '19990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'instruments',
      order: 0,
    },
    {
      id: 51,
      token: 'ea9b56a4-2890-46f5-92e2-ea41d4170a82',
      name: 'Дверь входная Luxor',
      price: '51490',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/ed3a9691-e628-48c8-a026-d960329d4ee7/main_icon.jpg',
      description:
        'Пятикамерный стеклопакет: 100 мм, тонированный. Толщина дверного полотна/короба: 130 мм / 141 мм. Терморазрыв 24 мм: двухкамерный спейсер из ПВХ на коробе и полотне двери. Наполнитель: PIR-плита. Порог из нержавеющей стали. Уплотнитель: 3 контура вставного уплотнителя из вспененной резины (2 на полотне, 1 на коробе)',
      discount: 0,
      endprice: '51490',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 10,
    },
    {
      id: 52,
      token: '5d373b7b-84ba-47d8-a003-f0aaa5022089',
      name: 'Танк Т-90М',
      price: '13990',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/6a00c54b-6676-4a23-9538-7ab049e5d9ae/main_icon.jpg',
      description:
        'Основной боевой танк Т-90М «Прорыв» (Объект 188М) — глубокая модернизация основного боевого танка Т-90.',
      discount: 0,
      endprice: '13990',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'models',
      betterInApp: false,
      order: 0,
    },
    {
      id: 53,
      token: '6593a079-3568-4748-a3f2-781e3ffcb4b3',
      name: 'Апельсиновое дерево',
      price: '12950',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/a94b0d54-2a22-43c6-9907-c8d16ef1442b/main_icon.jpg',
      description: 'Домашние апельсиновые деревья доставляют удовольствие своим видом и сладкими вкусными плодами.',
      discount: 0,
      endprice: '12950',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'exterior',
      order: 0,
    },
    {
      id: 54,
      token: '40d8b9be-48ec-49ff-b485-eb065d4ae591',
      name: 'Комплект из искусственного ротанга Софитель (Sofitel)',
      price: '39900',
      image:
        'https://storage.yandexcloud.net/system123.render.lab/calculationResults/8f60f164-4502-43c4-8a1e-8cbe11972c6d/main_icon.jpg',
      description:
        'Состоит из: \n- модульного углового дивана с подушками на сиденье и под спину \n- столика с закалённым стеклом 5мм. \nКонструкция: модули легко стыкуются, крепятся друг к другу и образуют угловой диван. \nМодуль - прочный стальной каркас оплетён долговечным добротным искусственным ротангом. Угол у дивана может быть только один, как на фото. \nПодушка: съёмный чехол, ткань специальная водоотталкивающая, приятная на ощупь, дорогая фактура. \nOutdoor: Можно использовать на улице, всепогодное. Не боится дождя и не выгорает на солнце.',
      discount: 0,
      endprice: '39900',
      company: 'Мебельная компания',
      gender: 'Men',
      type: 'furniture',
      order: 0,
    },
  ],
};
export default products;
export type ProductCard = (typeof products)['data'][number];
