import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  name: 'SearchComponent',
  
  setup() {
    const store = useStore();
    const filterSearch = computed<string>(() => store.state.filters['search']);
    const search = ref<string>('');

    onMounted(() => {
      if (filterSearch.value !== '') search.value = filterSearch.value;
    });

    const getSearch = () => {
      store.dispatch('set_filters', {
        filterType: 'search',
        filterValue: search.value,
      });
    };

    const checkForEndOfInput = (e : KeyboardEvent) =>
    {
      if( e.key != 'Enter' )
        return;
      if( router.currentRoute.value.path != '/' )
        router.push( {'path':'/'} );
    };
    
    return {
      search,
      getSearch,
      checkForEndOfInput
    };
  },
});
