import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { OhVueIcon, addIcons } from 'oh-vue-icons';
import {
  BiEye,
  BiEyeSlash,
  BiInstagram,
  BiTwitter,
  CoAccountLogout,
  CoTiktok,
  FaSpinner,
  HiHeart,
  HiMinusSm,
  HiSolidHeart,
  HiSolidPlus,
  LaFacebookF,
  LaTrashAltSolid,
  MdCloseRound,
  MdKeyboardarrowdown,
  MdKeyboardarrowleftOutlined,
  MdKeyboardarrowup,
  MdPlaceSharp,
  PrUser, //user icon
} from 'oh-vue-icons/icons';

addIcons(
  PrUser,
  MdKeyboardarrowleftOutlined,
  MdCloseRound,
  HiHeart,
  HiSolidHeart,
  MdKeyboardarrowup,
  MdKeyboardarrowdown,
  HiMinusSm,
  HiSolidPlus,

  BiInstagram,
  BiTwitter,
  CoTiktok,
  LaFacebookF,

  MdPlaceSharp,
  FaSpinner,
  CoAccountLogout,
  LaTrashAltSolid,
  BiEye,
  BiEyeSlash
);

createApp(App).component('v-icon', OhVueIcon).use(store).use(router).mount('#app');

// test
