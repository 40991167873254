import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20a7a248"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog" }
const _hoisted_2 = {
  key: 0,
  class: "genre"
}
const _hoisted_3 = { class: "genre__title" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.byBrand)
      ? (_openBlock(), _createElementBlock("article", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, "Brands " + _toDisplayString(_ctx.selectBrand !== '' ? '(1)' : ''), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BRANDS, (brand) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "types__types",
              key: brand + 'brands'
            }, [
              _createElementVNode("h4", {
                class: _normalizeClass(_ctx.selectBrand === brand ? 'types__select types__select--active' : 'types__select'),
                onClick: ($event: any) => (_ctx.getBrand(brand))
              }, _toDisplayString(brand.toLowerCase()), 11, _hoisted_4)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.byCategory)
      ? (_openBlock(), _createElementBlock("article", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.TYPES, (category) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "types__types",
              key: category
            }, [
              _createElementVNode("img", {
                src: require('@/assets/images/'+category.icon),
                alt: category.text
              }, null, 8, _hoisted_6),
              _createElementVNode("h4", {
                class: _normalizeClass(_ctx.selectCategory === category.id ? 'types__select types__select--active' : 'types__select'),
                onClick: ($event: any) => (_ctx.getCategory(category.id))
              }, _toDisplayString(category.text), 11, _hoisted_7)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}