import testdbMock from '@/testdb-mock';
import { ref } from 'vue';

const testDbPromise = (async () => {
  const scope = location.host.includes('dev') || location.host.includes('localhost') ? 'dev' : 'main';

  const response = await fetch(`https://backend.demostore.arigami.tech/storage/demopage-db-${scope}.json`);
  const json = (await response.json()) as typeof testdbMock;

  return json;
})();

export default testDbPromise;
export const testdbLoadingStatus = ref<'fetching' | 'errored' | 'ready'>('fetching');
export const testdb = ref<typeof testdbMock>(testdbMock);

testDbPromise
  .then((json) => {
    testdbLoadingStatus.value = 'ready';
    testdb.value = json;
  })
  .catch(() => {
    testdbLoadingStatus.value = 'errored';
  });
