import { TArrayString } from '@/interface/interface';
import categories from '@/testcategories';

export const featured = () => {
  
  const TYPES : any[] = categories.data;
  const BRANDS: TArrayString = ['FUTURE COMPANY', 'SNEAKER COMPANY', 'NEON COMPANY', 'RS-2K', 'CANVERSE'];

  return {
    TYPES,
    BRANDS,
  };
};
