const data = [
  {
    id: 'furniture',
    text: 'Мебель',
    icon: 'categories/furniture.png',
    order: 1,
  },
  {
    id: 'exterior',
    text: 'Сад и дача',
    icon: 'categories/exterior.png',
    order: 2,
  },
  {
    id: 'tech',
    text: 'Техника и электроника для дома',
    icon: 'categories/tech.png',
    order: 3,
  },
  {
    id: 'lighting',
    text: 'Светильники',
    icon: 'categories/lighting.png',
    order: 4,
  },
  {
    id: 'instruments',
    text: 'Станки и инструменты',
    icon: 'categories/instruments.png',
    order: 5,
  },
  {
    id: 'watches',
    text: 'Часы',
    icon: 'categories/watches.png',
    order: 6,
  },
  {
    id: 'models',
    text: 'Коллекционные модели',
    icon: 'categories/models.png',
    order: 7,
  },
  {
    id: 'helmets',
    text: 'Мотоциклетные шлемы',
    icon: 'categories/helmets.png',
    order: 8,
  },
  {
    id: 'householdGoods',
    text: 'Бытовые товары',
    icon: 'categories/householdGoods.png',
    order: 9,
  },
  {
    id: 'decorationMaterials',
    text: 'Отделочные материалы',
    icon: 'categories/decorationMaterials.png',
    order: 10,
  },
  {
    id: 'cloth',
    text: 'Одежда',
    icon: 'categories/cloth.png',
    order: 11,
  },
  {
    id: 'plumbing',
    text: 'Сантехника',
    icon: 'categories/plumbing.png',
    order: 12,
  },
  {
    id: 'plants',
    text: 'Растения и озеленение',
    icon: 'categories/plants.png',
    order: 13,
  },

  //{
  //  'id' : 'goods',
  //  'text' : 'FMCG',
  //  'icon' : 'categories/goods.png',
  //  'order' : 8
  //},
];

const updateOrder = function (products: any) {
  const local_categories = data;
  const getCategory = function (type: string) {
    return local_categories.find((c: any) => c.id == type);
  };

  const compareByOrder = function (d1: any, d2: any) {
    const getOrder = function (category: any): number {
      return category.order;
    };
    const o1 = getOrder(getCategory(d1.type));
    const o2 = getOrder(getCategory(d2.type));

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (d1.order < d2.order) return -1;
    if (d1.order > d2.order) return 1;
    return 0;
  };

  return products.sort(compareByOrder);
};

export default { data, updateOrder };
